<script>
import ResourceFrame from "../components/ResourceFrame.vue";
import DynamicTitle from "../../common/components/DynamicTitle.vue";
import aboutHeroImage from "../../../../public/img/resources/about-hero.png";
import { SuawInputGroup, SuawParagraph, SuawHeading, SuawThumbnail, SuawButton } from "@suaw/suaw-component-library";
export default {
  name: "About",
  components: { ResourceFrame, DynamicTitle, SuawInputGroup, SuawParagraph, SuawHeading, SuawThumbnail, SuawButton },
  data() {
    return {
      aboutHeroImage
    };
  },
  methods: {
    onFindAnEvent() {
      this.$router.push({
        name: "DashboardEvents"
      });
    }
  }
};
</script>

<template>
  <ResourceFrame>
    <SuawInputGroup direction="column" group-gap="quadruple" prevent-flex-grow>
      <DynamicTitle :before="['Welcome', 'To']" size="large" />
      <SuawHeading
        class="suaw-about-intro"
        level="3"
        content="Welcome to Shut Up & Write! (SUAW), a vibrant global community of writers who believe in the magic of writing together. Since 2007, we’ve been creating spaces—both online and in-person—where people can come together to write, share, and inspire one another."
      />
      <SuawThumbnail class="suaw-about-thumbnail" :content="aboutHeroImage" />
      <h2 class="suaw-about-subtitle">
        We help writers organize free writing events in their own communities.
      </h2>
      <SuawParagraph
        text="We believe that when we write together, the benefits extend far beyond simply producing words. Engaging with fellow writers fosters inspiration, accountability, and a shared sense of purpose, enriching the writing experience for everyone involved. (Also, it's fun.)"
      />
      <SuawParagraph
        text="At SUAW, we embrace every writer, whether you're a seasoned pro or just dipping your toes into the world of words. Our community is here to help you explore your projects, connect with others, and celebrate the joys of writing."
      />
      <h2 class="suaw-about-subtitle">
        Our Events
      </h2>
      <SuawParagraph
        text="Our events are free and open to anyone who wants to write. Using our simple methodology, each event has a straightforward structure: introductions, focused writing time, and a concluding check-in. During these events, we never share our work or critique others; we just get our writing done. Our events are hosted by organizers from our global network, so you'll find gatherings happening in local cafés or online, no matter where you are."
      />
      <div>
        <SuawButton class="suaw-about-button" size="large" button-text="Find an Event" @click="onFindAnEvent" />
      </div>
    </SuawInputGroup>
  </ResourceFrame>
</template>

<style lang="scss">
.suaw-about-intro {
  color: var(--sem-color-neutral-dark);
}
.suaw-about-subtitle {
  color: #414141;
  font-family: Archer;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: 125%;
}
.suaw-about-thumbnail {
  aspect-ratio: 2;
  @media (max-width: 650px) {
    aspect-ratio: 1.5;
  }
}
.suaw-about-button {
  @media (max-width: 650px) {
    width: 100%;
  }
}
</style>
